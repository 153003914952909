/* eslint-disable */
import Axios from 'axios';
import api from './api';

const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;


export default {

  getCamp(id) {
    return api.axiosGet(`/camp/${id}`);
  },

  getUserCamp() {
    return api.axiosGet('/camp/user');
  },
  
  
  
  async getAllUsersCamps(id) {
    return api.axiosGet(`/camps/usercamps/${id}`);
  },
  
  updateCamp(camp) {
    return api.axiosPut(`/camp/${camp.id}`, camp)
  },

  async getAllCampSessions(campId) {
    let sessions = api.axiosGet(`/campsession/${campId}`)
    await sessions
    return sessions
  },
  async getCampSessionById(sessionId) {
    let session = api.axiosGet(`/campsession/session/${sessionId}`)
    await session
    return session
  },

  async getAllUserCampSessions() {
      return api.axiosGet(`/campsession/usersessions`)
  },
  
  addNewSession(session) {
    console.log("adding a sessino", session)
    return api.axiosPost(`/campsession`, session)
  },
  
  updateCampSession(session) {
    return api.axiosPut(`/campsession/${session.CampSessionId}`, session)
  },
  
  archiveCampSession(sessionId) {
    return api.axiosPut(`/campsession/archive/${sessionId}`, sessionId)
  },
  CampSessionFull(sessionId, sessionFull) {
    return api.axiosPut(`/campsession/sessionfull/${sessionId}/${sessionFull}`)
  },
  CampSessionDraft(sessionId, draft) {
    return api.axiosPut(`/campsession/setsessiondraft/${sessionId}/${draft}`)
  },

  async refresh (jwt, rft) {
    let newT = ""
    await Axios.post(RESOURCE_NAME+`/login/refresh`, {
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'RefreshToken' : rft
      }
    })
    .then(result => {
      newT = result
      Vue.cookies.set("cit", result.split('|')[1] ,'3d', null, null, null, "Strict")
      Vue.cookies.set("rft", result.split('|')[0] , '3d', null, null, null, "Strict")
      return newT
    })
     .catch(error => {
      console.log("error refresh", error)
    });
  }
};